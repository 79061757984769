import makeStyles from '@mui/styles/makeStyles';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import { DepartmentData } from 'services/company-management/departments.service';
import ColorSquare from 'components/ColorSquare';
import ManageAccessTablesContainer from './components/ManageAccessTablesContainer';
import Restricted from 'components/Restricted';
import { PermissionCategory } from 'services/account-management';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

interface TreeItemRowLabelProps {
    color?: string;
    name: string;
};

const TreeItemRowLabel = (props: TreeItemRowLabelProps) => {
    const { color, name } = props;

    return (
        <Grid container alignItems="center" spacing={2}>
            {color && <Grid item>
                <ColorSquare color={color} />
            </Grid>}
            <Grid item>
                <Typography variant="h6">
                    {name}
                </Typography>
            </Grid>
        </Grid>
    )
};

const useTreeItemStyles = makeStyles((theme) => ({
    item: {
        marginTop: theme.spacing(2),
    },
}));

interface TreeItemRowProps {
    setSelectedDepartment: (id: number) => void;
    departments: DepartmentData[];
    dept: DepartmentData;
};

const TreeItemRow = (props: TreeItemRowProps) => {
    const { setSelectedDepartment, departments, dept } = props;
    const classes = useTreeItemStyles();
    return (
        <TreeItem
            nodeId={dept.id.toString()}
            label={<TreeItemRowLabel color={dept.color} name={dept.name} />}
            className={classes.item}
        >
            {departments.filter(row => row.parentDepartmentId === dept.id).map((childDept, index) => (
                <TreeItemRow
                    key={index}
                    dept={childDept}
                    departments={departments}
                    setSelectedDepartment={setSelectedDepartment} />
            ))}
        </TreeItem>
    )
}

export default function ManageAccessView() {
    const theme = useTheme();
    const mediumScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Restricted to={[PermissionCategory.ManageAccessGroup, PermissionCategory.ManageAccessPermissions]}>
            <Box m={smallScreen ? 1 : 3}>
                <Grid container spacing={2}>
                    {/* <Grid container item xs={mediumScreen ? 12 : 3} direction="column">
                        <Grid container item className={classes.sectionHeader} alignItems="center" justify="center">
                            <Grid item>
                                <Typography className={classes.title} variant="h6" component="div">
                                    {t("DEPARTMENTS LIST")}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Paper className={classes.listPaper}>
                                <TreeView
                                    selected={selectedDepartment.toString()}
                                    className={classes.treeView}
                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                    defaultExpandIcon={<ChevronRightIcon />}
                                >
                                    {departments.filter(row => row.parentDepartmentId === 0).map((dept, index) => (
                                        <TreeItemRow setSelectedDepartment={setSelectedDepartment} key={index} dept={dept} departments={departments} />
                                    ))}
                                </TreeView>
                            </Paper>
                        </Grid>
                    </Grid> */}
                    <Grid item xs={mediumScreen ? 12 : 12}>
                        <ManageAccessTablesContainer />
                    </Grid>
                </Grid>
            </Box>
        </Restricted>
    );
}