import React, { FormEvent, useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, Switch } from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import CheckIcon from '@mui/icons-material/Check';
import { useSnackbar, VariantType } from 'notistack';

import { useInput, useTimeInput } from 'hooks/input-hook';
import { Days, ScheduleCreateRequest, VerificationMethods } from 'services/schedules-management/types';
import { useSessionContext } from 'contexts/SessionContext';
import { getCreateErrorText, getGetErrorText, ResponseSuccessMessages } from 'services/genericService';
import { schedulesService } from 'services/schedules-management/schedules.service';
import { TimePicker } from '@mui/x-date-pickers';

const blipStyles = (chosen = false) => (chosen ? {
    backgroundColor: "#00A65A",
    color: "#fff",
    "&:hover": {
        backgroundColor: "#11B76B"
    },
} : {
    backgroundColor: "#707070",
    color: "#fff",
    "&:hover": {
        backgroundColor: "#818181"
    },
})

interface BlipProps {
    name: string;
    checked: boolean;
    onClick: (name: string) => void;
};

function DayBlip(props: BlipProps) {
    const handleOnClick = () => props.onClick(props.name);
    const [t] = useTranslation();

    return (
        <Button
            sx={{ py: 1, px: 2, ...blipStyles(props.checked) }}
            onClick={handleOnClick}
            startIcon={props.checked ? <CheckIcon /> : <PowerSettingsNewIcon />}
        >
            {t(props.name.charAt(0).toUpperCase() + props.name.slice(1))}
        </Button>
    )
};

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
}));

interface AddScheduleFormProps {
    editedSchedule?: string;
    handleClose: () => void;
};

interface DaysCheckedType {
    sunday: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    holidays: boolean;
    [key: string]: boolean;
};

export default function AddScheduleForm(props: AddScheduleFormProps) {
    const classes = useStyles();
    const [t] = useTranslation();
    const [sessionContext] = useSessionContext();
    const { enqueueSnackbar } = useSnackbar();
    const { editedSchedule, handleClose } = props;
    const { value: name, setValue: setName, bind: bindName } = useInput("");
    const { value: description, setValue: setDescription, bind: bindDescription } = useInput("");
    const { value: start, setValue: setStart, bind: bindStart } = useTimeInput();
    const { value: end, setValue: setEnd, bind: bindEnd } = useTimeInput();
    const [verification, setVerification] = React.useState<string>("CardOrPIN");
    const [allChecked, setAllChecked] = React.useState(false);
    const [daysChecked, setDaysChecked] = React.useState<DaysCheckedType>({
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        holidays: false,
    });

    const verificationMethods: string[] = ["CardOrPin", "Card", "Pin", "CardAndPin", "OpenAtTime"];

    useEffect(() => {
        if (editedSchedule) {
            schedulesService.getSchedule(editedSchedule, sessionContext.token)
                .then(res => {
                    setName(res.name);
                    setDescription(res.description);
                    setStart(res.start);
                    setEnd(res.end);
                    setVerification(res.verificationMethod);
                    setDaysChecked({
                        sunday: res.days[0] ? true : false,
                        monday: res.days[1] ? true : false,
                        tuesday: res.days[2] ? true : false,
                        wednesday: res.days[3] ? true : false,
                        thursday: res.days[4] ? true : false,
                        friday: res.days[5] ? true : false,
                        saturday: res.days[6] ? true : false,
                        holidays: res.days[7] ? true : false
                    });
                })
                .catch(err => handleSnackBar(getGetErrorText(err), "error"));
        }
    }, [editedSchedule]);

    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant });
    };

    const handleVerificationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVerification((event.target as HTMLInputElement).value);
    };

    const handleAllCheckedChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setAllChecked(event.target.checked);
        setDaysChecked({
            sunday: event.target.checked,
            monday: event.target.checked,
            tuesday: event.target.checked,
            wednesday: event.target.checked,
            thursday: event.target.checked,
            friday: event.target.checked,
            saturday: event.target.checked,
            holidays: event.target.checked,
        });
    };

    const onBlipClick = (key: string) => {
        const newDaysChecked = { ...daysChecked, [key]: !daysChecked[key] };
        Object.values(newDaysChecked).includes(false) ? setAllChecked(false) : setAllChecked(true);
        setDaysChecked(newDaysChecked);
    };

    const getVerificationMethod = (): VerificationMethods => {
        let res: VerificationMethods = VerificationMethods.CardOrPin;
        switch (verification) {
            case 'CardOrPin':
                res = VerificationMethods.CardOrPin;
                break;
            case 'Card':
                res = VerificationMethods.Card;
                break;
            case 'Pin':
                res = VerificationMethods.Pin;
                break;
            case 'CardAndPin':
                res = VerificationMethods.CardAndPin;
                break;
            case 'OpenAtTime':
                res = VerificationMethods.OpenAtTime;
                break;
        }
        return res;
    };

    async function handleOnSubmit(e: FormEvent): Promise<any> {
        e.preventDefault();

        if (name && start && end && end && verification) {
            const schedule: ScheduleCreateRequest = {
                name,
                description: description ? description : undefined,
                start,
                end,
                days: (daysChecked.sunday ? Days.Sunday : 0)
                    | (daysChecked.monday ? Days.Monday : 0)
                    | (daysChecked.tuesday ? Days.Tuesday : 0)
                    | (daysChecked.wednesday ? Days.Wednesday : 0)
                    | (daysChecked.thursday ? Days.Thursday : 0)
                    | (daysChecked.friday ? Days.Friday : 0)
                    | (daysChecked.saturday ? Days.Saturday : 0)
                    | (daysChecked.holidays ? Days.Holidays : 0),
                verificationMethod: getVerificationMethod()
            };

            if (editedSchedule) {
                schedulesService.updateSchedule(editedSchedule, schedule, sessionContext.token)
                    .then(() => {
                        handleSnackBar(ResponseSuccessMessages.EDIT, 'success');
                        handleClose();
                    })
                    .catch(error => {
                        handleSnackBar(getCreateErrorText(error), 'error');
                    });
            } else {
                schedulesService.createSchedule(schedule, sessionContext.token)
                    .then(() => {
                        handleSnackBar(ResponseSuccessMessages.ADD, 'success');
                        handleClose();
                    })
                    .catch(error => {
                        handleSnackBar(getCreateErrorText(error), 'error');
                    });
            }
        } else {
            handleSnackBar(getCreateErrorText(400), 'error');
        }
    };

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.paper}>
                <Box pb={4} mt={2}>
                    <Typography component="h1" variant="h5">
                        {t("Insert schedule data")}
                    </Typography>
                </Box>
                <form className={classes.form} noValidate onSubmit={handleOnSubmit}>
                    <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label={t("Name")}
                        name="name"
                        autoComplete="name"
                        autoFocus
                        {...bindName}
                    />
                    <TextField
                        variant="standard"
                        margin="normal"
                        multiline
                        fullWidth
                        id="description"
                        label={t("Description")}
                        name="description"
                        autoComplete="description"
                        {...bindDescription}
                    />
                    <Paper sx={{
                        my: 3,
                        px: {
                            xs: 2,
                            sm: 3,
                        },
                        py: 1,
                    }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm="auto" display="flex" alignItems="center">
                                {t("Active between")}
                            </Grid>
                            <Grid item xs={6} sm display="flex" alignItems="center">
                                <TimePicker
                                    label={t("From")}
                                    sx={{ width: '100%' }}
                                    {...bindStart}
                                />
                            </Grid>
                            <Grid item sx={{
                                display: {
                                    xs: "none",
                                    sm: "flex",
                                },
                                alignItems: 'center',
                            }}>
                                {t("and")}
                            </Grid>
                            <Grid item xs={6} sm display="flex" alignItems="center">
                                <TimePicker
                                    label={t("Until")}
                                    sx={{ width: '100%' }}
                                    {...bindEnd}
                                />
                            </Grid>
                        </Grid>
                    </Paper>

                    <FormControl component="fieldset">
                        <FormLabel component="legend">{t("Verification")}</FormLabel>
                        <RadioGroup row aria-label="verification" name="verification" value={verification} onChange={handleVerificationChange}>
                            {verificationMethods.map(method => (
                                <FormControlLabel key={method} value={method} control={<Radio size="small" />} label={t(method)} />
                            ))}
                        </RadioGroup>
                    </FormControl>
                    <Box mt={3} />
                    <Paper sx={{ py: 2, px: 3 }}>
                        <Grid container justifyContent="space-around" spacing={2}>
                            <Grid container item xs={12} sm={5} justifyContent="center">
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    {t("Days active")}
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} sm={7} justifyContent="center">
                                <Grid
                                    component="label"
                                    container
                                    item
                                    alignItems="center"
                                    spacing={1}
                                    sx={{
                                        justifyContent: {
                                            xs: "center",
                                            sm: 'flex-start',
                                        }
                                    }}>
                                    <Grid item>
                                        <Switch color='primary' checked={allChecked} onChange={handleAllCheckedChange} name="employedChecked" />
                                    </Grid>
                                    <Grid item>{t('Select all')}</Grid>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={2} justifyContent="center">
                                {Object.keys(daysChecked).map((day, index) => (
                                    <Grid item key={index}>
                                        <DayBlip name={day} checked={daysChecked[day]} onClick={onBlipClick} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Paper>

                    <Box mt={2} />
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleClose}
                            >
                                {t("Cancel")}
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleOnSubmit}
                            >
                                {t(editedSchedule ? "Save" : "Add schedule")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}