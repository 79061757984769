import React, { FormEvent, useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import CasinoIcon from '@mui/icons-material/Casino';
import { useSnackbar, VariantType } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormHelperText, Grid, IconButton, Input, InputAdornment, InputLabel, Link, MenuItem, Select, Switch, Tab, Tabs, Theme, useTheme } from '@mui/material';

import { useInput, useMultiselectInput, useSelectInput } from 'hooks/input-hook';
import { departmentsService } from 'services/company-management/departments.service';
import { locationsService } from 'services/company-management/locations.service';
import { devicesService } from 'services/devices.service';
import { ScheduleData, schedulesService } from 'services/schedules.service';
import { useSessionContext } from 'contexts/SessionContext';
import { DepartmentGetNamesResponse, employeesService, EmployeeUpdateRequest, LocationGetNamesResponse } from 'services/company-management';
import { RoleGetAllResponse, rolesService } from 'services/account-management';
import { getCreateErrorText, getGetErrorText, ResponseSuccessMessages } from 'services/genericService';
import { WorktimeScheduleEmployeeUpdateRequest, WorktimeScheduleGetShortNamesResponse } from 'services/setting-management/types/worktimeSchedules';
import { worktimeSchedulesService } from 'services/setting-management/worktimeSchedules.service';
import { Link as RouterLink } from 'react-router-dom';
import LocationSelect from 'components/LocationSelect';


interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    appBarSpacer: theme.mixins.toolbar,
    selectEmpty: {
        marginTop: theme.spacing(3),
    },
    link: {
        textDecoration: 'underline',
        fontWeight: 'bold',
    },
}));

function a11yProps(index: any) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
        value: index,
    };
}

const tabStyle = {
    minWidth: {
        xs: 100,
        sm: 140,
        md: 160,
    },
    px: 1,
};

interface EditEmployeeFormProps {
    editedEmployee: string;
    handleClose: () => void;
    refreshData: () => void;
}

export default function EditEmployeeForm(props: EditEmployeeFormProps) {
    const [t] = useTranslation();
    const classes = useStyles();
    const [sessionContext] = useSessionContext();
    const { editedEmployee, handleClose, refreshData } = props;
    const { enqueueSnackbar } = useSnackbar();
    
    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant });
    };


    // first tab
    const { value: firstName, bind: bindFirstName, setValue: setFirstName } = useInput("");
    const { value: lastName, bind: bindLastName, setValue: setLastName } = useInput("");
    const { value: locationsSelected, bind: bindLocationsSelected, setValue: setLocationsSelected } = useMultiselectInput([]);
    const { value: departmentsSelected, bind: bindDepartmentsSelected, setValue: setDepartmentsSelected } = useMultiselectInput([]);

    // sec tab
    const { value: email, bind: bindEmail, setValue: setEmail } = useInput("");
    const { value: role, bind: bindRole, setValue: setRole } = useSelectInput("0");
    const { value: phone, bind: bindPhone, setValue: setPhone } = useInput("", /^(\+)?(\d{0,9})$/);
    const { value: employeeId, bind: bindEmployeeId, setValue: setEmployedId } = useInput("", /^\d{0,10}$/);

    // third tab
    const { value: PIN, bind: bindPIN, setValue: setPIN } = useInput("", /^\d{0,4}$/);
    const { value: cardId, bind: bindCardId, setValue: setCardId } = useInput("", /^\d{0,16}$/);
    const [guestHostChecked, setGuestHostChecked] = React.useState(false);
    
    // fourth tab
    const { value: employedSince, setValue: setEmployedSince, bind: bindEmployedSince } = useInput("");
    const { value: employedUntil, setValue: setEmployedUntil, bind: bindEmployedUntil } = useInput("");
    const { value: worktimeScheduleSelected, setValue: setWorktimeScheduleSelected, bind: bindWorktimeScheduleSelected } = useSelectInput("0");
    const [employedChecked, setEmployedChecked] = React.useState(false);
    
    // data from api
    const [roles, setRoles] = React.useState<RoleGetAllResponse[]>([]);

    // additional data
    const [ firstNameError, setFirstNameError ] = React.useState<boolean>(false);
    const [ lastNameError, setLastNameError ] = React.useState<boolean>(false);
    const [ locationError, setLocationError ] = React.useState<boolean>(false);
    const [ departmentsError, setDepartmentsError ] = React.useState<boolean>(false);
    const [ emailError, setEmailError ] = React.useState<boolean>(false);
    const [ phoneError, setPhoneError ] = React.useState<boolean>(false);
    const [ employeeIdError, setEmployeeIdError ] = React.useState<boolean>(false);
    const [ pinError, setPinError ] = React.useState<boolean>(false);
    const [ cardIdError, setCardIdError ] = React.useState<boolean>(false);

    const [value, setValue] = React.useState('one');
    const [devices, setDevices] = React.useState<string[]>([]);
    const [schedules, setSchedules] = React.useState<WorktimeScheduleGetShortNamesResponse[]>([]);
    
    // reset errors after modify input
    useEffect(() => { setEmailError(false) }, [email]);
    useEffect(() => { setDepartmentsError(false) }, [departmentsSelected]);
    useEffect(() => { setFirstNameError(false) }, [firstName]);
    useEffect(() => { setLastNameError(false) }, [lastName]);
    useEffect(() => { setEmailError(false) }, [role]);
    useEffect(() => { setPhoneError(false) }, [phone]);
    useEffect(() => { setEmployeeIdError(false) }, [employeeId]);
    useEffect(() => { setPinError(false) }, [PIN]);
    useEffect(() => { setCardIdError(false) }, [cardId]);

    useEffect(() => {
        employeesService.getEmployee(editedEmployee, sessionContext.token)
            .then(res => {
                setFirstName(res.firstName);
                setLastName(res.lastName);
                setLocationsSelected(res.locations.map(l => l.uuid))
                setDepartmentsSelected(res.departments.map(d => d.uuid))

                setEmail(res.email ?? "")
                setRole(res.role ? res.role.uuid : "0")
                setPhone(res.phoneNumber ?? "")
                setEmployedId(res.code ?? "")

                setPIN(res.pin ?? "")
                setCardId(res.cardNumber ?? "")
                setGuestHostChecked(!!res.visitorsAllowed);
            })
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
        
        rolesService.getRoles(sessionContext.token)
            .then(res => setRoles(res))
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
        
        worktimeSchedulesService.getAll(sessionContext.token)
            .then(res => setSchedules(res))
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'))
            
        worktimeSchedulesService.getEmployeeWorktimeSchedule(editedEmployee, sessionContext.token)
            .then(res => setWorktimeScheduleSelected(res.uuid))
            .catch(err => {/*handleSnackBar(getGetErrorText(err), 'error')*/})
    }, []);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setValue(newValue);
    };

    const handleDevicesChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setDevices(event.target.value as string[]);
    };
    
    const handleGuestHostSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGuestHostChecked(event.target.checked);
    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmployedChecked(event.target.checked);
        setEmployedSince("");
        setEmployedUntil("");
    };

    const handleRandomPin = () => {
        employeesService.getRandomPin(sessionContext.token)
            .then(res => setPIN(res.pin))
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
    }

    const handleOnSubmit = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        
        if (!validateOk()) return;

        let dept: EmployeeUpdateRequest = {
            firstName,
            lastName,
            locations: locationsSelected.map(uuid => ({ uuid })),
            departments: departmentsSelected.map(uuid => ({ uuid })),
            visitorsAllowed: guestHostChecked
        };

        if (email.length > 0) dept.email = email;
        if (role !== "0") dept.roleUuid = role;
        if (phone.length > 0) dept.phoneNumber = phone//.replace(/\s|-/g, "");
        if (employeeId.length > 0) dept.code = employeeId;
        if (PIN.length > 0) dept.pin = PIN;
        if (cardId.length > 0) dept.cardNumber = cardId;

        const deptWorktime: WorktimeScheduleEmployeeUpdateRequest = {
            uuid: worktimeScheduleSelected === '0' ? undefined : worktimeScheduleSelected,
        }
        
        try {
            await employeesService.updateEmployee(editedEmployee, dept, sessionContext.token)
            await worktimeSchedulesService.updateEmployeeWorktimeSchedule(editedEmployee, deptWorktime, sessionContext.token)

            handleSnackBar(ResponseSuccessMessages.EDIT, "success")
            refreshData();
            handleClose();
        } catch (e) {
            const err: any = e;

            switch (err?.data?.message) {
                case "Pin already exists":
                    setPinError(true);
                    setValue('three')
                    break;
                case "The card number already exists":
                    setCardIdError(true);
                    setValue('three')
                    break;
                default:
                    handleSnackBar(getCreateErrorText(err?.status), 'error');
                    return;
            }

            handleSnackBar(err.data.message, 'error');
        }
    }


    const validateOk = (): boolean => {
        let temp = false;
        
        if (firstName.length === 0) {
            setFirstNameError(true);
            temp = true;
        }

        if (lastName.length === 0) {
            setLastNameError(true);
            temp = true;
        }

        if (locationsSelected.length === 0) {
            setLocationError(true);
            temp = true;
        }

        if (departmentsSelected.length === 0) {
            setDepartmentsError(true);
            temp = true;
        }
        
        if (temp) {
            handleSnackBar("You have to fill in all spaces", 'error')
            setValue('one')
            return false;
        }

        const mailFormat = /^\w+([.-]?\w+)*(\+\w+)?@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if ((role !== "0" || email.length > 0) && !mailFormat.test(email)) {
            setEmailError(true);
            handleSnackBar("Wrong email address", 'error')
            setValue('two')
            return false;
        }

        const phoneFormat = /^(\+)?(\d{9})$/;
        if (phone.length > 0 && !phoneFormat.test(phone)) {
            setPhoneError(true);
            handleSnackBar("Wrong phone number", 'error')
            setValue('two')
            return false;
        }

        const employeeIdFormat = /^\d*$/;
        if (employeeId.length > 0 && !employeeIdFormat.test(employeeId)) {
            setEmployeeIdError(true);
            handleSnackBar("Wrong employee ID", 'error')
            setValue('two')
            return false;
        }
        
        const pinFormat = /^\d{4}$/;
        if (PIN.length > 0 && !pinFormat.test(PIN)) {
            setPinError(true);
            handleSnackBar("Wrong PIN", 'error')
            setValue('three')
            return false;
        }
        
        const cardIdFormat = /^\d*$/;
        if (cardId.length > 0 && !cardIdFormat.test(cardId)) {
            setCardIdError(true);
            handleSnackBar("Wrong card ID", 'error')
            setValue('three')
            return false;
        }

        return true;
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    {t("Insert employee data")}
                </Typography>
                <div className={classes.appBarSpacer} />
                <Tabs
                    variant="fullWidth"
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label={t("Basic info")} sx={tabStyle} {...a11yProps('one')} />
                    <Tab label={t("Additional")} sx={tabStyle} {...a11yProps('two')} />
                    <Tab label={t("Access")} sx={tabStyle} {...a11yProps('three')} />
                    <Tab label={t("Additional_RCP")} sx={tabStyle} {...a11yProps('four')} />
                </Tabs>
                <form className={classes.form} noValidate onSubmit={handleOnSubmit}>

                    <TabPanel value={value} index="one" >
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            id="firstName"
                            label={t("First name")}
                            name="firstName"
                            autoComplete="firstName"
                            autoFocus
                            error={firstNameError}
                            {...bindFirstName}
                        />
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            id="lastName"
                            label={t("Last name")}
                            name="lastName"
                            autoComplete="lastName"
                            error={lastNameError}
                            {...bindLastName}
                        />

                        <FormControl fullWidth sx={{ mt: 3 }} required>
                            <LocationSelect
                                required
                                multiple
                                noOverrideDefaultValues
                                error={locationError}
                                setValue={setLocationsSelected}
                                {...bindLocationsSelected}
                            />
                        </FormControl>

                        <FormControl fullWidth sx={{ mt: 3 }} required>
                            <LocationSelect
                                type="department"
                                required
                                multiple
                                noOverrideDefaultValues
                                error={departmentsError}
                                setValue={setDepartmentsSelected}
                                input={<Input />}
                                {...bindDepartmentsSelected}
                            />
                        </FormControl>

                    </TabPanel>

                    <TabPanel value={value} index="two" >
                        <TextField
                            variant="standard"
                            margin="normal"
                            fullWidth
                            required={role !== "0"}
                            id="email"
                            label={t("Email Address")}
                            name="email"
                            autoComplete="email"
                            error={emailError}
                            autoFocus
                            {...bindEmail}
                        />

                        <FormControl fullWidth sx={{ mt: 3 }} variant='standard'>
                            <InputLabel id="role-select-label"> {t("Choose role")}</InputLabel>
                            <Select
                                fullWidth
                                displayEmpty
                                labelId="role-select-label"
                                id="role-select"
                                {...bindRole}
                            >
                                <MenuItem value={"0"}>
                                    {t("No role")}
                                </MenuItem >
                                {roles.map((role, index) => (
                                    <MenuItem key={index} value={role.uuid}>
                                        {role.name}
                                    </MenuItem >
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            variant="standard"
                            margin="normal"
                            fullWidth
                            id="phone"
                            label={t("Phone number")}
                            name="phone"
                            autoComplete="phone"
                            error={phoneError}
                            {...bindPhone}
                        />
                        <TextField
                            variant="standard"
                            margin="normal"
                            fullWidth
                            id="employeeId"
                            label={t("Employee ID")}
                            name="employeeId"
                            autoComplete="employeeId"
                            error={employeeIdError}
                            {...bindEmployeeId}
                        />
                    </TabPanel>

                    <TabPanel value={value} index="three" >

                        <TextField
                            variant="standard"
                            margin="normal"
                            fullWidth
                            id="cardId"
                            label={t("Card ID")}
                            name="cardId"
                            autoComplete="cardId"
                            autoFocus
                            error={cardIdError}
                            {...bindCardId}
                        />
                        <TextField
                            variant="standard"
                            margin="normal"
                            fullWidth
                            id="PIN"
                            label={t("PIN")}
                            name="PIN"
                            autoComplete="PIN"
                            error={pinError}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="get random pin"
                                            onClick={handleRandomPin}
                                            onMouseDown={e => e.preventDefault()}
                                            size="large">
                                            <CasinoIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            {...bindPIN}
                        />
                        <Grid component="label" container alignItems="center" justifyContent="space-between" spacing={1}>
                            <Grid item>{t('Guest host')}</Grid>
                            <Grid item>
                                <Switch color='primary' checked={guestHostChecked} onChange={handleGuestHostSwitchChange} name="employedChecked" />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    
                    <TabPanel value={value} index="four" >

                        {/* <Box mt={2} display={"block"}>
                            <Grid component="label" container justify="center" alignItems="center" spacing={1}>
                                <Grid item>{t('Employed')}</Grid>
                                <Grid item>
                                    <Switch color='primary' checked={employedChecked} onChange={handleSwitchChange} name="employedChecked" />
                                </Grid>
                                <Grid item>{t('Unemployed')}</Grid>
                            </Grid>
                        </ Box>
                        {!employedChecked
                            && <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="employedSince"
                                label={t("Employed since")}
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                {...bindEmployedSince}
                            />}
                        {!employedChecked
                            && <TextField
                                margin="normal"
                                fullWidth
                                id="employedUntil"
                                label={t("Employed until")}
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                {...bindEmployedUntil}
                            />} */}

                        <FormControl fullWidth sx={{ mt: 3 }} variant='standard'>
                            <InputLabel id="schedule-select-label"> {t("Choose schedule")}</InputLabel>
                            <Select
                                fullWidth
                                // disabled={!departments[0] || locationsSelected === ""}
                                displayEmpty
                                id="schedule-select"
                                sx={{ mt: 3 }}
                                disabled={!schedules.length}
                                {...bindWorktimeScheduleSelected}
                            >
                                <MenuItem value={"0"}>{t("Nothing assigned")}</MenuItem>
                                {schedules.map(schedule => (
                                    <MenuItem key={schedule.uuid} value={schedule.uuid}>
                                        {schedule.name}
                                    </MenuItem >
                                ))}
                            </Select>
                            {!schedules.length && (
                                <FormHelperText>
                                    {`${t(`Add work time schedule`)} ${t("in")}`} <Link to={`/settings/work-time`} color="inherit" component={RouterLink} className={classes.link}>{t("Work time schedules")}</Link>
                                </FormHelperText>
                            )}
                        </FormControl>

                        {/* <FormControl fullWidth sx={{ mt: 3 }}>
                            <InputLabel id="device-select-label">{t("Choose device")}</InputLabel>
                            <Select
                                labelId="device-select-label"
                                id="device-select"
                                multiple
                                value={devices}
                                onChange={handleDevicesChange}
                                input={<Input />}
                                MenuProps={MenuProps}
                            >
                                {devicesService.getAllNames().map((device) => (
                                    <MenuItem key={device.id} value={device.id} style={getStyles(device.name, devices, theme)}>
                                        {device.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> */}
                    </TabPanel>

                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary">
                                {t("Save")}
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleClose}>
                                {t("Cancel")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}