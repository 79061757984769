import {
    Box,
    Button,
    Container,
    CssBaseline,
    Grid,
    Tab,
    Tabs,
    Typography
} from '@mui/material';
import { useSessionContext } from "contexts/SessionContext";
import { useSnackbar, VariantType } from "notistack";
import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { getCreateErrorText, getGetErrorText, ResponseSuccessMessages } from 'services/genericService';
import { WorktimeScheduleCreateRequest, WorktimeShiftScheduleCreateRequest } from 'services/setting-management/types/worktimeSchedules';
import { worktimeSchedulesService } from 'services/setting-management/worktimeSchedules.service';
import { useCheckboxInput, useInput, useMultiselectInput, useNumberInput, useTimeInput } from 'hooks/input-hook';
import TabPanel from 'components/TabPanel';
import AddWorkTimeStepOne from './steps/AddWorkTimeStepOne';
import AddWorkTimeStepTwo from './steps/AddWorkTimeStepTwo';
import AddWorkTimeStepThree from './steps/AddWorkTimeStepThree';

function a11yProps(index: any) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
        value: index,
    };
}

const tabStyle = {
    minWidth: {
        xs: 100,
        sm: 140,
        md: 160,
    },
    px: 1,
};


interface AddWorkTimeScheduleListProps {
    selectedUuid?: string;
    handleClose?: () => void;
}

function AddWorkTimeSchedule(props: AddWorkTimeScheduleListProps) {
    const { selectedUuid, handleClose } = props;
    const [t] = useTranslation();
    const [sessionContext] = useSessionContext();
    const { value: name, setValue: setName, bind: bindName } = useInput("");
    const { value: shiftScheduleChecked, setValue: setShiftScheduleChecked, bind: bindShiftScheduleChecked } = useCheckboxInput(false);
    const [ shifts, setShifts ] = useState<WorktimeShiftScheduleCreateRequest[]>([]);
    const { value: dayStart, setValue: setDayStart, bind: bindDayStart } = useTimeInput();
    const { value: nightHoursFrom, setValue: setNightHoursFrom, bind: bindNightHoursFrom } = useTimeInput();
    const { value: nightHoursTo, setValue: setNightHoursTo, bind: bindNightHoursTo } = useTimeInput();
    const { value: breakTime, setValue: setBreakTime, bind: bindBreakTime } = useNumberInput(15, 1, 120);
    const { value: roundTime, setValue: setRoundTime, bind: bindRoundTime } = useNumberInput(5, 1, 30);
    const { value: roundDownChecked, setValue: setRoundDownChecked, bind: bindRoundDownChecked } = useCheckboxInput(false);
    const { value: departmentsSelectedUuid, bind: bindDepartmentsSelectedUuid, setValue: setDepartmentsSelectedUuid } = useMultiselectInput([]);
    const { value: selectedEmployeesUuid, bind: bindSelectedEmployeesUuid, setValue: setSelectedEmployeesUuid } = useMultiselectInput([]);
    const [value, setValue] = useState('one');

    const { enqueueSnackbar } = useSnackbar();
    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant, preventDuplicate: true });
    };

    useEffect(() => {
        loadData()
    }, [selectedUuid]);

    const loadData = () => {        
        if (!selectedUuid) return resetData();
        
        worktimeSchedulesService.getWorktimeSchedule(selectedUuid, sessionContext.token)
            .then(res => {
                setName(res.name);
                setShiftScheduleChecked(res.isShiftSchedule);
                setDayStart(res.dayStart);
                setNightHoursFrom(res.nightStart);
                setNightHoursTo(res.nightEnd);
                setBreakTime(res.breakTimeInMinutes);
                setRoundTime(res.roundBreakTimeInMinutes);
                setRoundDownChecked(res.isRoundToDown);
                setShifts(res.shifts);
                setSelectedEmployeesUuid(res.employees.map(e => e.uuid));
                setDepartmentsSelectedUuid(res.departments.map(d => d.uuid));
            })
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'))
    }

    const resetData = () => {
        setName("");
        setShiftScheduleChecked(false);
        setDayStart("00:00");
        setNightHoursFrom("22:00");
        setNightHoursTo("06:00");
        setBreakTime(15);
        setRoundTime(5);
        setRoundDownChecked(false);
        // setLocation("");
        setSelectedEmployeesUuid([]);
        setDepartmentsSelectedUuid([]);

        setShifts([{
            shiftNumber: 1,
            countFrom: "08:00",
            start: "08:00",
            stop: "16:00",
            countUntil: "16:00",
            recognizeFrom: "08:00",
            recognizeUntil: "16:00",
        }])
    }

    const handleOnSubmit = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        if (!ifValidateOk()) return;

        const dept: WorktimeScheduleCreateRequest = {
            name,
            isShiftSchedule: shiftScheduleChecked,
            shifts,
            dayStart: dayStart ?? "",
            nightStart: nightHoursFrom ?? "",
            nightEnd: nightHoursTo ?? "",
            breakTimeInMinutes: breakTime,
            roundBreakTimeInMinutes: roundTime,
            isRoundToDown: roundDownChecked,
            employees: selectedEmployeesUuid.map((uuid: string) => ({ uuid })),
            departments: departmentsSelectedUuid.map((uuid: string) => ({ uuid })),
        }

        try {
            if (selectedUuid) await worktimeSchedulesService.updateWorktimeSchedule(selectedUuid, dept, sessionContext.token);
            else await worktimeSchedulesService.createWorktimeSchedule(dept, sessionContext.token);

            if (handleClose) handleClose()
            resetData();
            handleSnackBar(selectedUuid ? ResponseSuccessMessages.EDIT : ResponseSuccessMessages.ADD, "success");
        } catch (err) {
            handleSnackBar(getCreateErrorText(err as number), 'error')
        }
    }

    const timeStringToMinutes = (time?: string) => time?.toString().split(":").map((n, i) => parseInt(n) * (i ? 1 : 60)).reduce((a, b) => a + b, 0)

    const ifValidateOk = (): boolean => {
        for (const shift of shifts) {
            const countFromInMinutes = timeStringToMinutes(shift.countFrom)
            const startInMinutes = timeStringToMinutes(shift.start)
            const stopInMinutes = timeStringToMinutes(shift.stop)
            const countUntilInMinutes = timeStringToMinutes(shift.countUntil)
            
            if (countFromInMinutes && startInMinutes && countFromInMinutes > startInMinutes) {
                handleSnackBar("'Start' must be after 'Count from'", "error")
                return false;
            }

            if (stopInMinutes && countUntilInMinutes && countFromInMinutes && stopInMinutes > countUntilInMinutes && countUntilInMinutes > countFromInMinutes) {
                handleSnackBar("'Count until' must be after 'Stop' and before 'Count from'", "error")
                return false;
            }
        }

        return true;
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setValue(newValue);
    };
    
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box sx={{ mt: 8, display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Typography component="h1" variant="h5" sx={{ mb: 8 }}>
                    {t("Insert work time schedule")}
                </Typography>

                <Tabs
                    variant="fullWidth"
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label={t("Basic info")} sx={tabStyle} {...a11yProps('one')} />
                    <Tab label={t("Access schedule")} sx={tabStyle} {...a11yProps('two')} />
                    <Tab label={t("Access")} sx={tabStyle} {...a11yProps('three')} />
                </Tabs>

                <Box sx={{ width: '100%', mt: 1 }} component="form" onSubmit={handleOnSubmit}>
                    <TabPanel value={value} index="one">
                        <AddWorkTimeStepOne
                            bindDayStart={bindDayStart}
                            bindName={bindName}
                            bindNightHoursFrom={bindNightHoursFrom}
                            bindNightHoursTo={bindNightHoursTo}
                            bindBreakTime={bindBreakTime}
                            bindRoundTime={bindRoundTime}
                            bindRoundDownChecked={bindRoundDownChecked}
                        />
                    </TabPanel>
                    <TabPanel value={value} index="two">
                        <AddWorkTimeStepTwo
                            shifts={shifts}
                            bindShiftScheduleChecked={bindShiftScheduleChecked}
                            setShifts={setShifts}
                        />
                    </TabPanel>
                    <TabPanel value={value} index="three">
                        <AddWorkTimeStepThree
                            bindDepartmentsSelectedUuid={bindDepartmentsSelectedUuid}
                            bindSelectedEmployeesUuid={bindSelectedEmployeesUuid}
                        />
                    </TabPanel>

                    <Box sx={{ width: "100%", p: { sm: 1 } }}>
                        <Grid container spacing={2} direction="row-reverse">
                            <Grid item xs>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                >
                                    {t("Save")}
                                </Button>
                            </Grid>
                            <Grid item xs>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={handleClose}
                                >
                                    {t("Cancel")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}

export default AddWorkTimeSchedule;
