import { Box, Button, Card, CardContent, CardHeader, Divider, FormControl, FormLabel, Grid, IconButton, Paper, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteIcon from '@mui/icons-material/Delete';
import { WorktimeShiftScheduleCreateRequest } from "services/setting-management/types";
import { useTimeInput } from "hooks/input-hook";
import { TimePicker } from "@mui/x-date-pickers";
import { format, parse } from "date-fns";

interface ShiftRowProps {
    shift: WorktimeShiftScheduleCreateRequest;
    handleEditRow: (newRow: WorktimeShiftScheduleCreateRequest, index: number) => void;
    shiftScheduleChecked: boolean;
    shiftNumber: number;
    disabledDelete: boolean;
    removeShift: (index: number) => void;
}

function ShiftRow(props: ShiftRowProps) {
    const { shift, handleEditRow, shiftScheduleChecked, shiftNumber } = props;
    const defaultTimeParams = useTimeInput().bind;
    const [t] = useTranslation();

    const parseDate = (date: string) => parse(date, defaultTimeParams.format, new Date());
    const getFieldParams = (fieldName: keyof WorktimeShiftScheduleCreateRequest) => {
        return {
            ...defaultTimeParams,
            value: parseDate(shift[fieldName] as string),
            onChange: (value: unknown) => {
                handleEditRow({ ...shift, [fieldName]: format(value as Date, defaultTimeParams.format) }, shiftNumber);
            }
        }
    }

    const getTitle = (fieldName: keyof WorktimeShiftScheduleCreateRequest) => {
        const titleText = () => {
            if (fieldName === 'shiftNumber') return "Shift";
            if (fieldName === 'countFrom') return "Count from";
            if (fieldName === 'start') return "Start";
            if (fieldName === 'stop') return "Stop";
            if (fieldName === 'countUntil') return "Count until";
            if (fieldName === 'recognizeFrom') return "Recognize from";
            if (fieldName === 'recognizeUntil') return "Recognize until";
            return "Actions";
        }
        return t(titleText());
    }

    const fields: (keyof WorktimeShiftScheduleCreateRequest)[] = [
        'countFrom',
        'start',
        'stop',
        'countUntil', 
    ];
    if (shiftScheduleChecked) fields.push('recognizeFrom');
    if (shiftScheduleChecked) fields.push('recognizeUntil');

    return (
        <Grid container rowGap={2}>
            {fields.map(fieldName => (
                <Grid container item xs={6} sm={['recognizeFrom', 'recognizeUntil'].includes(fieldName) ? 6 : 3}>
                    <Grid item xs={12} sx={{ px: 1 }}>{getTitle(fieldName)}</Grid>
                    <Grid item xs={12}>
                        <TimePicker
                            sx={{ width: "100%", px: 1, "& .MuiInputBase-input ": { pl: 1, pr: 0 } }}
                            {...getFieldParams(fieldName)}
                        />
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
}

interface AddWorkTimeStepTwoProps {
    shifts: WorktimeShiftScheduleCreateRequest[];
    bindShiftScheduleChecked: {
        checked: boolean;
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    };
    setShifts: React.Dispatch<React.SetStateAction<WorktimeShiftScheduleCreateRequest[]>>;
}

function AddWorkTimeStepTwo(props: AddWorkTimeStepTwoProps) {
    const [t] = useTranslation();
    const { shifts, bindShiftScheduleChecked, setShifts } = props;
    const shiftScheduleChecked = bindShiftScheduleChecked.checked;

    const leaveFirstShift = () => {
        const temp = shifts.slice();

        temp.splice(1);

        setShifts(temp)
    }

    const addNewShift = () => {
        const temp = shifts.slice();

        temp.push({
            shiftNumber: temp.length + 1,
            countFrom: "08:00",
            start: "08:00",
            stop: `16:00`,
            countUntil: "16:00",
            recognizeFrom: "08:00",
            recognizeUntil: "16:00",
        })

        setShifts(temp);
    }

    const handleEditRow = (newRow: WorktimeShiftScheduleCreateRequest, index: number) => {
        const temp = shifts.slice();

        temp[index] = newRow;

        setShifts(temp);
    }

    const removeShift = (index: number) => {
        const temp = shifts.slice();

        temp.splice(index, 1);

        setShifts(temp);
    }

    return (
        <>
            <Grid item xs={12} container sx={{ px: { sm: 2 } }}>
                <Grid item sx={{ width: "100%" }}>
                    <Paper sx={{ p: 2, pb: 0 }}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">{t("Choose schedule type")}</FormLabel>
                            <Grid container item justifyContent="space-around">
                                <Box display="block">
                                    <Grid component="label" container alignItems="center" spacing={1}>
                                        <Grid item>{t('Standard schedule (1 shift)')}</Grid>
                                        <Grid item>
                                            <Switch color='primary' name="shiftScheduleChecked" {...bindShiftScheduleChecked} onClick={leaveFirstShift} />
                                        </Grid>
                                        <Grid item>{t('Shift schedule')}</Grid>
                                    </Grid>
                                </ Box>
                            </Grid>
                        </FormControl>
                    </Paper>
                </Grid>
            </Grid>
            <Grid item xs={12} container sx={{ py: 2, px: { sm: 2 } }} direction="column">
                {shiftScheduleChecked && (
                    <Grid item sx={{ mb: 2 }}>
                        <Button variant="contained" color="primary" onClick={addNewShift} sx={{ width: { xs: "100%", sm: 'unset' } }}>
                            {t("Add schedule")}
                        </Button>
                    </Grid>
                )}
                <Grid item xs sx={{ maxWidth: "100% !important" }}>
                    <Paper>
                        <FormControl sx={{ width: "100%" }}>
                            {shifts.map((shift, index) => (
                                <Card key={index}>
                                    {index > 0 && <Divider />}
                                    
                                    <CardHeader
                                        subheader={`${t("Schedule declaration")} ${shiftScheduleChecked ? index + 1 : ""}`}
                                        action={
                                            <IconButton
                                                aria-label="delete"
                                                onClick={() => removeShift(index)}
                                                disabled={shifts.length === 1}
                                                size="large">
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                    />
                                    <CardContent sx={{ px: 0 }}>
                                        <ShiftRow
                                            shiftScheduleChecked={shiftScheduleChecked}
                                            shift={shift}
                                            handleEditRow={handleEditRow}
                                            shiftNumber={index}
                                            disabledDelete={shifts.length === 1}
                                            removeShift={removeShift}
                                        />
                                    </CardContent>
                                </Card>
                            ))}
                        </FormControl>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default AddWorkTimeStepTwo;
