import { Button, ClickAwayListener, Popper, TextField } from '@mui/material';
import { Locale, endOfMonth, endOfWeek, format, startOfMonth, startOfWeek } from 'date-fns';
import { pl } from 'date-fns/locale';
import { DateRange } from './types';
import DateRangePickerWrapper from './components/DateRangePickerWrapper';
import { DateRangeIcon } from '@mui/x-date-pickers';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const getRanges = (t: TFunction, locale?: Locale) => {
  const curDate = new Date();

  return [
    {
      startDate: curDate,
      endDate: curDate,
      label: t("day")
    },
    {
      startDate: startOfWeek(curDate, { locale }),
      endDate: endOfWeek(curDate, { locale }),
      label: t("week")
    },
    {
      startDate: startOfMonth(curDate),
      endDate: endOfMonth(curDate),
      label: t("month")
    },
  ];
}

interface DateRangePickerProps {
  dateRange: DateRange;
  onChange: (a: DateRange) => void;
  handleClose?: () => void;
  inputType?: 'button' | 'input';
  maxDaysBetween?: number;
  minDate?: Date | string;
  maxDate?: Date | string;
}

function DateRangePicker(props: DateRangePickerProps) {
  const { dateRange, onChange, inputType, maxDaysBetween, minDate } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [t] = useTranslation();
  const id = open ? 'date-range-popper' : undefined;
  const locale = pl;
  const maxDate = props.maxDate ?? endOfMonth(new Date());

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (props.handleClose) props.handleClose();
  }

  const dateFormat = "dd/MM/yyyy";

  const inputValue = `${dateRange.startDate ? format(dateRange.startDate, dateFormat) : ""} - ${dateRange.endDate ? format(dateRange.endDate, dateFormat) : ""}`;

  return (
    <>
      {
        inputType === 'button'
        ? (
          <Button aria-describedby={id} variant="contained" onClick={handleClick}>
            <DateRangeIcon />
          </Button>
        ) : (
          <TextField
            aria-describedby={id}
            onClick={handleClick}
            value={inputValue}
            variant='outlined'
            sx={{
              "& input": {
                width: 192,
                cursor: "pointer",
                userSelect: "none",
              }
            }}
          />
        )
      }
      {open && <ClickAwayListener onClickAway={handleClose}>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          sx={theme => ({
            zIndex: theme.zIndex.modal
          })}>
          <DateRangePickerWrapper
            onChange={onChange}
            initialDateRange={dateRange}
            locale={locale}
            definedRanges={getRanges(t, locale)}
            maxDaysBetween={maxDaysBetween}
            minDate={minDate}
            maxDate={maxDate}
          />
        </Popper>
      </ClickAwayListener>}
    </>
  );
}

export default DateRangePicker;